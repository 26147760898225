import React from 'react';
import { Link, OffsiteIcon, Paragraph } from '@argo/ui-elements';
import { colors, FlexBox, size } from '@argo/principles';
import styled from '@emotion/styled';
import ddpDataAnalytics from '../../utils/ddpDataAnalytics';
import ddpPixallEvents from '../../utils/pixAll/ddpPixAllEvents';

const StyledParagraph = styled(Paragraph)`
  color: ${colors.blue.darkBrightBlue};
`;

const StyledSpan = styled.span`
  display: inline-flex;
  WebkitBoxAlign: center;
  align-items: center;
  line-height: ${size.none}px;
  padding: ${size.micro}px;
  vertical-align: bottom;
  border-radius: ${size.xs12}px;
  background-color: ${colors.primary.white};
`;

const DealerWebsiteLink = ({ serviceSchedulingUrl, ddpDataLayer, ddpPixallDataLayer, displayedDealer }) => {
  const { provider, svocId, type } = displayedDealer;
  const handleAnalytics = (event) => {
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.dealerServiceSchedulingLinkClick(svocId, provider, type));
    ddpPixallEvents.fireDdpPixallClick( ddpPixallDataLayer, ddpPixallEvents.clicks.dealerLinkClickDdp(svocId, provider));

    if (typeof caiEventBus !== 'undefined') {
      const adobeEvent = {
        reaxlName: 'adobeClickOnlyWebComponent',
        dealerId: svocId,
        event: event,
        cmp: 'dlr_lnk'

      }
      caiEventBus.emit('serviceTab:adobeevent', { eventMessage: 'Dealer Website has been clicked', eventData: adobeEvent });
    }
  };

  return (
    <Link href={serviceSchedulingUrl} rel="noopener" onClick={handleAnalytics}>
      <FlexBox style={{ margin: '0px' }} alignItems="center">
        <StyledParagraph type="XSSemi">Visit dealer website </StyledParagraph>
        <StyledSpan data-cy="dealerWebsiteLink">
          <OffsiteIcon size="16" color="darkBrightBlue" />
        </StyledSpan>
      </FlexBox>
    </Link>
  );
};

export default DealerWebsiteLink;
