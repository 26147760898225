import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { validateEmailInput, validateNameInput } from '../../utils/InputUtil';
import { useLazyQuery } from '@apollo/client';
import { promoEmailResponseQuery } from '../../tags/queries';
import { Click } from '../../constants/analytics';
import { colors, size, FlexBox } from '@argo/principles';
import { Button, InnerLabelInputField } from '@argo/ui-elements';
import { useStepByStepFormUnlocker } from '@argo/utils';
import ddpDataAnalytics from '../../../utils/ddpDataAnalytics';
import ddpPixallEvents from '../../../utils/pixAll/ddpPixAllEvents';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const RequiredField = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: ${colors.primary.charcoal};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.7px;
  margin-top: ${size.sm}px;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

//analytics 4B tagging spec

const PromoEmailForm = ({
  setSubmitIsDisabled,
  updateInputFormVariables,
  setFormHasBeenSubmitted,
  dealerInfo,
  onClose,
  coupon,
  ddpDataLayer,
  ddpPixallDataLayer
}) => {
  // const analytics = useAnalytics();
  const [errorState, setErrorState] = useState({
    recipientEmail: false,
    senderName: false,
    senderEmail: false
  });
  const { formState, fieldOnChange, submitIsDisabled, onSubmit, submitUrl } = useStepByStepFormUnlocker({
    mapping: {
      recipientEmail: {
        index: 0,
        nonSequential: true,
        validator: (el) => {
          return validateEmailInput(el.value);
        }
      },
      senderName: {
        index: 1,
        nonSequential: true,
        validator: (el) => {
          return validateNameInput(el.value);
        }
      },
      senderEmail: {
        index: 2,
        nonSequential: true,
        validator: (el) => {
          return validateEmailInput(el.value);
        }
      }
    },
    onSubmitCallback: (e) => {
      sendEmail(e);
    }
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  let vehicle = {};

  const [submitPromoEmailQuery, { data, error, loading }] = useLazyQuery(promoEmailResponseQuery, {
    onCompleted: () => {
      setFormHasBeenSubmitted(true);
    },
    ssr: false
  });

  // there will most likely be more instances where there is no vehicle in context unless default vehicle
  // when user clicks on coupon and sends to email

  // const localVehicleJson = getVehiclePickerData();
  // const localStorageVehicle = localVehicleJson ? localVehicleJson : {};

  useEffect(() => {
    const isDisabled = submitIsDisabled;
    if (!isDisabled) {
      setInputFormVariables();
    }
    setSubmitIsDisabled(isDisabled);
  }, [submitIsDisabled]);

  const setInputFormVariables = () => {
    updateInputFormVariables(formState.recipientEmail.value, formState.senderName.value, formState.senderEmail.value);
  };

  const handleFocusEvent = (e) => {
    setErrorState((prevErrorState) => ({
      ...prevErrorState,
      [e.target.id]: false
    }));
  };

  const updateErrorState = (e) => {
    const targetId = e.target.id;
    const targetValue = e.target.value;
    if (targetId.includes('recipientEmail')) {
      return setErrorState({
        ...errorState,
        recipientEmail: !validateEmailInput(targetValue)
      });
    }
    if (targetId.includes('senderName')) {
      return setErrorState({
        ...errorState,
        senderName: !validateNameInput(targetValue)
      });
    }
    if (targetId.includes('senderEmail')) {
      return setErrorState({
        ...errorState,
        senderEmail: !validateEmailInput(targetValue)
      });
    }
  };

  const handleBlurEvent = (e) => {
    return updateErrorState(e);
  };

  // invoked whenever input is received from the argo InnerLabelInputField component
  const handleOnChange = (e) => {
    fieldOnChange(e);
    if (e.target.id === document.activeElement.id) return;
    return updateErrorState(e);
  };

  // called when the user clicks the 'Send Email' button
  const sendEmail = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    ddpDataAnalytics.fireDdpClick(ddpDataLayer,ddpDataAnalytics.clicks.sendPromoEmailClick(dealerInfo.svocId, dealerInfo.provider, dealerInfo.type));
    ddpPixallEvents.fireDdpPixallClick(ddpPixallDataLayer,ddpPixallEvents.clicks.promoCardEmailShareDdp(dealerInfo.svocId, dealerInfo.provider));
    if (typeof caiEventBus !== 'undefined') {
      const adobeEvent = {
        reaxlName: 'adobeClickOnlyWebComponent',
        event: e,
        dealerId: dealerInfo.svocId,
        cmp: 'dlr-promo_send-btn'

      }
      caiEventBus.emit('serviceTab:adobeevent', { eventMessage: 'Send Email CTA has been clicked', eventData: adobeEvent });
    }
    // call new BFF endpoint using combination of dealerInfo object and user data submitted from the form.
    const promoEmailInfo = {
      SenderName: formState.senderName.value,
      SenderEmail: formState.senderEmail.value,
      RecipientEmail: formState.recipientEmail.value,
      PromoText: coupon,
      DealerId: dealerInfo.dealerId || dealerInfo.id,
      DealerName: dealerInfo.name,
      DealershipAddress: dealerInfo.address,
      DealershipCity: dealerInfo.city,
      DealershipState: dealerInfo.state,
      DealershipZipCode: dealerInfo.zip,
      DealershipPhone: dealerInfo.phone
    };
    submitPromoEmailQuery({
      variables: { promoEmailInfo }
    });

    // lastly, set setFormHasBeenSubmitted to true to show thank you message <Handled in useEffect to ensure query call is in flight before
    // displaying the thank you message.
  };

  return (
    <div data-cy="promoEmailForm">
      <StyledForm id="savePromoForm" action={submitUrl} onSubmit={onSubmit} noValidate data-testid="promo-form">
        <RequiredField>*Required Fields</RequiredField>
        <FlexBox default={['1 1 50%', '1 1 50%', '1 1 50%', '1 1 50%']} flexWrap="wrap">
          <InnerLabelInputField
            autoFocus
            data-input-type="Email"
            id="recipientEmail"
            labelText="Recipient's Email"
            data-field-index={formState.recipientEmail.index}
            maxLength={null}
            noValidate
            placeholder=""
            required
            requiredText=""
            type="text"
            width="auto"
            value={formState.recipientEmail.value}
            error={errorState.recipientEmail}
            errorText="Please enter a valid email address"
            onChange={handleOnChange}
            onFocus={handleFocusEvent}
            onBlur={handleBlurEvent}
            data-testid="recipientEmailInput"
          />
          <InnerLabelInputField
            data-input-type="Name"
            id="senderName"
            labelText="Your Name"
            data-field-index={formState.senderName.index}
            maxLength={null}
            noValidate
            placeholder=""
            required
            requiredText=""
            type="text"
            width="auto"
            value={formState.senderName.value}
            error={errorState.senderName}
            errorText="Please enter a valid name"
            onChange={handleOnChange}
            onFocus={handleFocusEvent}
            onBlur={handleBlurEvent}
            data-testid="nameInput"
          />
          <InnerLabelInputField
            data-input-type="Email"
            id="senderEmail"
            labelText="Your Email"
            data-field-index={formState.senderEmail.index}
            maxLength={null}
            noValidate
            placeholder=""
            required
            requiredText=""
            type="email"
            width="auto"
            value={formState.senderEmail.value}
            error={errorState.senderEmail}
            errorText="Please enter a valid email address"
            onChange={handleOnChange}
            onFocus={handleFocusEvent}
            onBlur={handleBlurEvent}
            data-testid="yourEmailInput"
          />
        </FlexBox>
        <StyledButton
          buttonType="primary"
          text="Send Email"
          minimizeSize={false}
          target="_self"
          enableCapitalize
          disabled={submitIsDisabled || formSubmitted}
          type="submit"
          data-cy="send_promo_email_btn"
          data-testid="sendEmailCta"
        />
      </StyledForm>
    </div>
  );
};

export default PromoEmailForm;
